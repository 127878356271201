import React from "react";
import styles from "./objectEntry.module.scss";

export const ObjectEntry: React.FC<{}> = ({ children }) => (
  <li>
    <span className={styles.h3}>{"{"}</span>
    <div className={styles.objectEntry}>{children}</div>
    <span className={styles.h3}>{"}"}</span>
  </li>
);
