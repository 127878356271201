import React from "react";
import { ObjectEntry } from "../objectEntry/objectEntry";

export const ExperienceEntry: React.FC<{
  name: string;
  employer: string;
  start: string;
  end: string;
  description: string;
}> = ({ name, employer, start, end, description }) => (
  <ObjectEntry>
    <h3>
      <small>position:</small> {name}
    </h3>
    <h4>
      <small>employer:</small> {employer}
    </h4>
    <h5>
      <small>time_start:</small> {start}
    </h5>
    <h5>
      <small>time_end:</small> {end}
    </h5>
    <p>
      <small>description:</small> {description}
    </p>
  </ObjectEntry>
);
