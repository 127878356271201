import React from "react";
import { ObjectEntry } from "../objectEntry/objectEntry";
import { List } from "../list/list";

export const ProjectEntry: React.FC<{
  techRole: string;
  tech: string[];
  description: string;
}> = ({ techRole, tech, description }) => {
  return (
    <ObjectEntry>
      <h3>
        <small>role:</small> {techRole}
      </h3>
      <h4>
        <small>primary_tech:</small> {"["}
      </h4>
      <List depth={3}>
        {tech.map((tech, index) => (
          <li key={index}>{tech}</li>
        ))}
      </List>
      {"]"}
      <p>
        <small>description:</small> {description}
      </p>
    </ObjectEntry>
  );
};
