import React from "react";
import styles from "./list.module.scss";

export const List: React.FC<{
  depth: number;
  className?: string;
}> = ({ children, className, depth }) => {
  return (
    <ul className={[styles[`listDepth${depth}`], className].join(' ')}>
      {children}
    </ul>
  );
};
